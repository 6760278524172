import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useLayoutEffect, useRef } from "react";
/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

import { Switch, Route, useLocation } from "react-router-dom";
import HomePage from "booking-campus/pages/HomePage";
import ContactUs from "booking-campus/pages/ContactUs";
import TermOfUse from "booking-campus/pages/TermOfUse";
import TermOfService from "booking-campus/pages/TermOfService";
import PrivacyPolicy from "booking-campus/pages/PrivacyPolicy";
import ScrollControl from "booking-campus/components/ScrollControl";
import { ChatWidget } from "@papercups-io/chat-widget";
import messagesIcon from "images/bc/icons/messages.svg";
import tw, { theme } from "twin.macro";

const PageContainer = tw.div`pt-16`;

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const {
    REACT_APP_CHAT_WIDGET_TOKEN,
    REACT_APP_CHAT_WIDGET_INBOX,
    REACT_APP_CHAT_WIDGET_BASE_URL,
    REACT_APP_CHAT_WINDOW_URL
  } = process.env;
  const { pathname } = useLocation();
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    containerRef.current.scrollIntoView({ inline: "center" });
  }, [pathname]);
  return (
    <PageContainer ref={containerRef}>
      <ScrollControl />
      <Switch>
        <Route path="/contact-us">
          <ContactUs />
        </Route>
        <Route path="/terms-of-use">
          <TermOfUse />
        </Route>
        <Route path="/terms-of-service">
          <TermOfService />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
      <ChatWidget
        token={REACT_APP_CHAT_WIDGET_TOKEN}
        inbox={REACT_APP_CHAT_WIDGET_INBOX}
        title="BookingCampus Team"
        subtitle="Online Support"
        primaryColor={theme`colors.bc.warning1`}
        greeting="Hi there, welcome to BookingCampus! How can I help you?"
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={false}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={true}
        iconVariant="filled"
        baseUrl={REACT_APP_CHAT_WIDGET_BASE_URL}
        onChatLoaded={() => {}}
        customer={
          {
            // name: 'Test User',
            // email: 'test@test.com',
            // external_id: '123',
            // metadata: {version: 1, plan: 'premium'}, // Custom fields go here
          }
        }
        customIconUrl={messagesIcon}
        styles={{
          chatContainer: {
            right: "1rem",
            bottom: "6rem",
          },
          toggleContainer: {
            right: "1rem",
            bottom: "3rem",
          },
          toggleButton: {
            width: "2.5rem",
            height: "2.5rem",
            padding: "0.5rem",
          },
        }}
        iframeUrlOverride={REACT_APP_CHAT_WINDOW_URL}
      />
    </PageContainer>
  );
}
